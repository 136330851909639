import React, { useEffect, useState } from 'react';
import "../Css/SuccessPage.css";
import { PiSealCheckFill } from "react-icons/pi";
import deliverymanriding from "../Assert/delivery-guy-on-Scooter.jpeg";
import { RxCross2 } from "react-icons/rx";
// import ProductData from '../Component/ProductData';
import { useCartContext } from '../context/addToCart';
// import EmptyComp from ".././Component/Empty/empty";
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { get } from '../apiService';
import { message } from 'antd';
const SuccessPage = () => {
    const { cartValue, setCartCount, amountDetails, setCartValue } = useCartContext();

    const { id } = useParams();
    console.log(id);
    const removeCartData = (index) => {
        const prevData = cartValue;
        prevData.splice(index, 1);
        addtoLocatStorage(prevData);
        setCartCount(prevData?.length);
    }

    // =================== add to local Storage ==================

    const addtoLocatStorage = (data) => {
        localStorage.setItem("cart", JSON.stringify(data));
    };


    const [orderProduct, setOrderProduct] = useState(null);

    const fetchData = async () => {
        try {
            const response = await get(`/orders.php?admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&action=fetch&order_id=${id} &page=1&limit=1`);
           if(response){
            // message.success('order fetch')
               setOrderProduct(response.list[0]);
           }
            // /orders.php?admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&action=fetch&page=1&limit=10&id=79
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        fetchData();
        setCartCount(0);
        setCartValue([]);
        localStorage.setItem("cart", JSON.stringify([]));
        localStorage.clear()
    }, [id]);
    // 
    console.log("Order Product", orderProduct)


    return (
        <>
            <section className='SuccessPage-Section-con '>
                <div className='container '>
                    <div className='SuccessPage-Section'>
                        <div className='SuccessPage-Section-Details-con'>
                            <div className='order-Success-con'>
                                <div className='order-Success-con-icons'>
                                    <PiSealCheckFill />
                                    <h2>Order Confirmed</h2>
                                    <p>Congratulations! Your order with Kataria's  has been successfully confirmed. We appreciate your business and are thrilled to fulfill your delicious cravings. Expect a delightful experience as our team prepares and dispatches your order with care. Thank you for choosing Kataria's – where flavor meets satisfaction.</p>
                                </div>
                                <div className='Order-Success-Details-con-Main'>
                                    <div className='Order-Success-Details-con-Main-head'>
                                        <div className='Order-Success-List-head'>
                                            <h3>Order Id : - <span>{orderProduct && orderProduct.order_id}</span></h3>
                                        </div>
                                        <div className='Order-Success-List-head'>
                                            <h3>Ordered on : - <span>{orderProduct && moment(orderProduct.created_at).format("llll")}</span></h3>
                                        </div>
                                    </div>
                                    <div className='Order-Success-Details-con'>
                                        <div className='Order-Success-Details-add'>
                                            <h1>delivering to :</h1>
                                            <div className='Order-Success-Details-name-contact'>
                                                <h2>
                                                    {orderProduct && orderProduct.user_name}
                                                </h2>
                                                <span>
                                                    |
                                                </span>
                                                <h2>
                                                    {orderProduct && orderProduct.user_phone}
                                                </h2>
                                            </div>
                                            <div className='Order-Success-Details-address'>
                                                <p>{orderProduct && orderProduct.address},
                                                 {/* {orderProduct && orderProduct.address_2},  */}
                                                 {orderProduct && orderProduct.city}</p>
                                            </div>
                                        </div>
                                        <div className='Order-Success-Details-Image-con'>
                                            <img
                                                src={deliverymanriding}
                                                alt="" />
                                        </div>
                                    </div>
                                </div>


                                <div className='Order-Success-List-con'>
                                    <div class="Order-Success-List">
                                        <div className='Product-addedCart-Side-Bar Successpage-Product-addedCart-Side-Bar' id="style-4">
                                            {
                                                orderProduct && orderProduct?.products?.map((elem, index) => {
                                                    console.log("first", elem)
                                                    const { size, image, color } = elem
                                                    return (
                                                        <>
                                                            <div className='Product-addedCart-Side-details'>
                                                                <div className='Product-addedCart-Image-con'>
                                                                    <div className='Product-addedCart-Image'>
                                                                        <img src={elem.image} />
                                                                    </div>
                                                                </div>
                                                                <div className='Product-details-addedCart-price-name SuccessPageprice'>
                                                                    <div className='Product-details-addedCart-price'>
                                                                        <h2>{elem.product_name}</h2>
                                                                        <h3><span>Color : </span>{elem.color_name}</h3>
                                                                        <h3><span>Size : </span>{elem.product_size}
                                                                         {/* x {elem.Product_Width} */}
                                                                         </h3>
                                                                    </div>
                                                                    {/* <h4>{elem.product_price}/- Rs</h4> */}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='Product-details-Addedcart-total-con'>
                                            <div className='Product-details-Addedcart-total'>
                                                <div className='Product-details-Addedcart-total-title'>
                                                    <h4>Total:</h4>
                                                </div>
                                                <div className='Product-details-Addedcart-total-Amount'>
                                                    <h5>{orderProduct && orderProduct.amount} /- Rs</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='order-success-page-btn-con'>
                                            <Link to="/">
                                                <button>
                                                    Back to home
                                                </button>
                                            </Link>
                                            <Link to="/product/all">
                                                <button>
                                                    Continue Shopping
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SuccessPage